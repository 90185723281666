* {

  color: white;


  text-align: center;
  font-size: 1.3rem;
}

/* .bg {
  min-width: 100%;
  min-height: 100vh;
  background-size: 100% 100%;
  background-color: green;
  background-size: cover;
  width: 100%;
  position: relative;
  object-fit: cover;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0;
} */


.bg {
  min-width: 100%;
  min-height: 100vh;
  background-color: black;
  background-position: center center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  /* object-fit: cover;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0; */
  position: absolute;
  top: 0;
  left: 0;

  min-width: 100%;
  min-height: 100%;
}

.bgImage {
  /* background-image:
    repeating-radial-gradient(circle, rgba(0, 0, 0, 0.72), rgba(0, 0, 0, 0.75)),
    url("../public/img/tvBackgroundNoText.png"); */
  background-image: url("../public/img/tvBackgroundNoText.png");
  background-position: center;
  background-repeat: no-repeat;
  /* background-size: 50% 50%; */
  width: 50rem;
  height: 30rem;
  margin: auto;
}

.statusBg {
  /* background-color: black;

  background-repeat: no-repeat;
  background-image:
    repeating-radial-gradient(circle, rgba(0, 0, 0, 0.72), rgba(0, 0, 0, 0.75)),
    url("../public/img/technoBg.png");
  background-size: 100% 100%; */

}


.navbar {
  text-transform: uppercase;
  animation-name: nav-animation;
  animation-duration: 3s;
  /* width: 250px !important; */
  /* top: 10px !important; */
  z-index: 2;
  justify-content: center;

}



p {
  /** TEXT GRADIENT */
  /* color: #E300F2; */
  /* background-image: -webkit-linear-gradient(45deg, #6020F7 50%, #E300F2 50%);
  background-clip: text;
  -webkit-background-clip: text;
  text-fill-color: transparent;
  -webkit-text-fill-color: transparent; */
  font-weight: 900;
}

.nav-link {
  margin-right: 25px;

}

url,
.url {
  width: 700px;
  color: black;
  text-align: left;
  border-radius: 25px;
  margin-top: 20px;
}

.info {
  position: relative;
  display: inline;
  margin: 100px;
}

.infoBg {
  width: 1100px;
  height: 300px;
}

.infoIcon {
  position: absolute;
  top: -80px;
  bottom: 0;
  right: 0;
  left: 60px;
  width: 200px;
  height: 200px;
}

.infoHeading {
  position: absolute;
  top: -80px;
  bottom: 0;
  right: 0;
  left: 350px;
  width: 500px;
  text-transform: uppercase;
}

.infoBlueHeading {

  color: #0068FF;
}

.infoYellowHeading {
  color: #FFB600;
}

.infoRedHeading {
  color: #E84024;
}

.swearFreePara {
  position: absolute;
  top: -10px;
  bottom: 0;
  right: 0;
  left: 270px;
  width: 800px;
  color: white;
}


.disclaimerImage {
  width: 200px;
  height: 200px;
  margin: 40px;
  left: 30px;
  top: -115px;
}



.logo {
  width: 200px;
  height: 100px;
  /* margin-left: -120%;
  margin-right: 2.5rem; */
}


.swearingEmoji {
  width: 100px;
  height: 100px;
  float: left;
}

#counters {

  text-align: left;
  color: greenyellow;
}

#red {
  color: red;
}

#green {
  color: greenyellow;
}

#videoPlayer {
  position: relative;
  left: 30%;
}


#displaySwearingText::after {
  content: "SWEARING\a(MUTED)";
  white-space: pre;
  font-size: 2rem;
  /* display: block; */
  z-index: 1;
  position: relative;
  width: 100px;
  height: 100px;

  top: 200px;
  left: 0px;
  font-weight: bold;
  /* border: 20px solid #E300F2; */
  /* border: 20px solid red; */
  color: red;
  animation-name: swearingTextAnimation;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

.reactPlayer {
  margin-left: -200px;
}

.show {
  display: block;
}

.hide {
  display: none;
}

@keyframes swearingTextAnimation {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* DISCLAIMER SECTION */
#disclaimer {
  display: inline;
  margin: 50px;
}

/* DISCLAIMER SECTION */

/* FAQ SECTION */

#faq {
  display: inline;
  margin: 50px;
}

.accordion {
  width: 70%;
  left: 10%;
  background-color: #484A4C !important;

}

.accordHeading {
  font-size: 1.5rem !important;
  color: #E300F2;
}

.accordPara {
  font-size: 1.2rem !important;
  color: white;
}

/* FAQ SECTION */

/* FEATURES SECTION */

.svgLine {
  stroke: white;
  stroke-width: 3;

}

#features {
  display: inline;
  margin: 50px;
}

.featuresIcons {
  width: 200px;
  height: 200px;

}

.featuresContainer {}

li {
  text-decoration: none;

  display: inline-block;
  overflow: hidden;
  /* white-space: nowrap; */
  /* text-overflow: ellipsis; */



}

.featurePara {
  /* margin-top: -200px; */
  position: relative;
  font-weight: bold;

}

.swearWord {
  margin-left: -260px;
}

.swearCounter {
  margin-left: -230px;
}

.easyToUse {
  margin-left: -140px;
}

.noDownload {
  margin-left: 100px;
}

.autoDetect {
  margin-left: -100px;
}

/* NEW FEAUTES */
.featuresContainer {}

.featureGradient {
  background-clip: text;
  -webkit-background-clip: text;
  text-fill-color: transparent;
  -webkit-text-fill-color: transparent;
  font-weight: 900;
}

#redGradient {
  background-image: -webkit-linear-gradient(45deg, #FF6A6E 0%, #D40005 100%);
}

#greenGradient {
  background-image: -webkit-linear-gradient(45deg, #B7D333 0%, #5DBA45 100%);
}

#pinkGradient {
  background-image: -webkit-linear-gradient(45deg, #D24F9D 0%, #A3228D 100%);
}

#orangeGradient {
  background-image: -webkit-linear-gradient(45deg, #E99E17 0%, #F15B21 100%);
}

#yellowGradient {
  background-image: -webkit-linear-gradient(45deg, #F8D53F 0%, #FCB117 100%);
}

#purpleGradient {
  background-image: -webkit-linear-gradient(45deg, #8979BA 0%, #473E8F 100%);
}

#tealGradient {
  background-image: -webkit-linear-gradient(45deg, #57E1E6 0%, #00AAA7 100%);
}

.featureListIcon {
  width: 200px;
  height: 200px;
}

.featureList {
  position: relative;
  display: inline;
  margin: 100px;
}

.featureListAutoDetect {
  position: absolute;
  top: -150px;
  bottom: 0;
  right: 0;
  left: 150px;

}

.featureListIconBottom {
  top: -200px;
}

.featureListHeading {
  font-family: 'Open Sans';
  font-weight: 1000;
  font-size: 38px;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: white;
  position: absolute;
  bottom: -180px;
  right: 0;
  left: 110px;
  width: 300px;
}

.featureHeadingSecure {
  bottom: -280px;
}

.featureHeadingDetect {
  bottom: -125px;
}

.featureHeadingWorksEvery {
  bottom: -170px;
}

.featureHeadingAudioVideo {
  bottom: -230px;
}

.featureHeadingCountdown {
  bottom: -120px;
}

/* NEW FEAUTES */


.footer-grid-container {
  /* display: grid; */
  /* grid-template-columns: 30% 30% 30%; */
  /* grid-template-rows: 300px; */
  /* border: 50px solid green; */
  /* background-color: #2196F3; */
  /* padding: 10px;
  margin-top: -100px; */


}

/* .footer-grid-item {
  padding: 20px;
  font-size: 30px;
  text-align: center;
} */

/* FEATURES SECTION */

/* DISCLAIMER SECTION */

.disclaimerPara {
  text-align: left;
  margin-left: 15%;
}

/* DISCLAIMER SECTION */


/* TEXT GRADIENT */
/* color: #6020F7;
  background-image: -webkit-linear-gradient(45deg, #6020F7 50%, #E300F2 50%);
  background-clip: text;
  -webkit-background-clip: text;
  text-fill-color: transparent;
  -webkit-text-fill-color: transparent;
  font-weight: 900; */