/* body {
    margin: 0;
    height: 100vh;
    display: grid;
    place-items: center;
    background: #161718;
} */

button {
    font: 700 30px consolas;
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
    padding: 20px 60px;
    position: relative;
    overflow: hidden;
    border-radius: 5px;
    transition: 0.2s;
    transform: scale(2);
    border: none;
    /* margin-left: 120px; */
    /* ADDED */
    margin-top: 50px;
    width: 100px;
    height: 50px;
    margin-bottom: 50px;

}


button span {
    position: relative;
    z-index: 0;
    color: #fff;
    margin-left: -40px;
    top: -15px;
}

button .liquid {
    position: absolute;
    top: -40px;
    left: 0;
    width: 100%;
    height: 120px;
    background: #E300F2;
    box-shadow: inset 0 0 50px rgba(0, 0, 0, 0.7);
    z-index: -1;
    transition: 0.6s;
}

button .liquid::after,
button .liquid::before {
    position: absolute;
    content: "";
    width: 200%;
    height: 200%;
    top: 0;
    left: 0;
    transform: translate(-25%, -75%);
}

button .liquid::after {
    border-radius: 45%;
    background: rgba(20, 20, 20, 1);
    box-shadow: 0 0 10px 5px #6020F7, inset 0 0 5px #6020F7;
    animation: animate 5s linear infinite;
    opacity: 0.8;
}

button .liquid::before {
    border-radius: 40%;
    box-shadow: 0 0 10px rgba(26, 26, 26, 0.5),
        inset 0 0 5px rgba(26, 26, 26, 0.5);
    background: rgba(26, 26, 26, 0.5);

    animation: animate 7s linear infinite;
}

@keyframes animate {
    0% {
        transform: translate(-25%, -75%) rotate(0);
    }

    100% {
        transform: translate(-25%, -75%) rotate(360deg);
    }
}

/* button:hover .liquid {
    top: -120px;
}

button:hover {
    box-shadow: 0 0 5px #6020F7, inset 0 0 5px #6020F7;
    transition-delay: 0.2s;
    cursor: pointer;
} */

button:hover {
    animation: grow 2s linear infinite;
}


/* write me an animation for the button that grows and shrinks */
@keyframes grow {
    0% {
        transform: scale(1.5);
    }

    50% {
        transform: scale(2);
    }

    100% {
        transform: scale(1.5);
    }
}