* {
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
    outline: none;
}

/* body {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #454342
} */

/* margin-left: 20px;
    margin-top: 20px; */

/* .neonShadow {
    height: 150px;
    width: 700px;
    border: none;
    border-radius: 50px;
    transition: 0.3s;
    background-color: rgba(54, 187, 92, 0.3);
    animation: glow 1s infinite;
    transition: 0.5s;



} */

#url {
    /* display: block; */
    /* width: 100%;
    height: 100%; */
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 700;
    /* padding-top: 15%; */
    /* padding-right: 2.5%; */
    /* margin-right: 0px; */
    font-size: 1.2rem;
    transition: 0.3s;
    opacity: 1;
    /* CHANGED from 0 to 1*/
    /* CHANGED */
    width: 700px;
    height: 75px;
    color: black;
    text-align: left;
    /* CHANGED */

    /* background-color: rgba(54, 187, 92, 0.3); */

    /** TEXT GRADIENT */
    color: #6020F7;
    background-image: -webkit-linear-gradient(45deg, #6020F7 50%, #E300F2 50%);
    /* background-clip: text;
    -webkit-background-clip: text;
    text-fill-color: transparent;
    -webkit-text-fill-color: transparent; */


    animation: glow 1s infinite;
    color: white;
}

#url:hover {
    transition: 0.3s;
    opacity: 1;
    font-weight: 700;
}

/* 
.neonShadow:hover {
    transform: translateX(-20px)rotate(30deg);
    border-radius: 5px;
    background-color: #c3bacc;
    transition: 0.5s;
} */

@keyframes glow {
    0% {
        box-shadow: 5px 5px 20px rgb(93, 52, 168), -5px -5px 20px rgb(93, 52, 168);
    }

    50% {
        box-shadow: 5px 5px 20px rgb(81, 224, 210), -5px -5px 20px rgb(81, 224, 210)
    }

    100% {
        box-shadow: 5px 5px 20px rgb(93, 52, 168), -5px -5px 20px rgb(93, 52, 168)
    }
}